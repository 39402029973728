import React, { useRef, useEffect, useState, useMemo, memo } from "react";
import styled from "styled-components";
import * as d3 from "d3";

function getLineData(items) {
  const itemsSorted = [...items].sort((a, b) => {
    return a.x - b.x;
  });

  const sampleLength = Math.floor(0.05 * itemsSorted.length);

  let linePoints = [];
  let sampleMeanX = 0;
  let sampleMeanY = 0
  itemsSorted.forEach((sample, index) => {
    if (index % sampleLength === 0 && index !== 0) {
      const xCor = sampleMeanX;
      const yCor = sampleMeanY;
      linePoints.push({ x: xCor, y: yCor });
      sampleMeanX = 0;
      sampleMeanY = 0;
    }

    sampleMeanX = (sampleMeanX + sample.x) / 2;
    sampleMeanY = (sampleMeanY + sample.y) / 2;

    if (index === itemsSorted.length - 1) {
      const xCor = sampleMeanX;
      const yCor = sampleMeanY;
      linePoints.push({ x: xCor, y: yCor });
    }
  });

  return linePoints;
}

const RegressionLine = ({ xScale, yScale, items, width }) => {
  const lineData = getLineData(items);

  const lineBuilder = d3
    .line()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y))
    .curve(d3.curveBundle.beta(0.5)); // https://d3js.org/d3-shape/curve

  const linePlotPath = lineBuilder(lineData);
  const linePlot = (
    <path d={linePlotPath} stroke="#fc3136" fill="none" strokeWidth={2} />
  );

  return <g className="scatterplotLine">{linePlot}</g>;
};

const MemoRegressionLine = memo(RegressionLine, (_prev, _next) => {
  const { items, width } = _prev;
  const { items: itemsNext, width: widthNext } = _next;
  if(width !== widthNext || JSON.stringify(items) !== JSON.stringify(itemsNext)){
    return false;
  }
  return true;
});

export default MemoRegressionLine;
