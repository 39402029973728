import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import ActionableView from "./common/ActionableView";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
import {SubscriberGroupsAndDashboard} from "views/Status/SubscriberGroups/SubscriberGroupsAndDashboard";
import {SubscribersAndDetails} from "views/Status/Subscribers/SubscribersAndDetails";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";
import BtaTraffic from "views/Statistics/Throughput/BtaTraffic/component";
import Interfaces from "views/Statistics/Throughput/Interfaces/component";
import SubscriberGroupEdit from "views/Status/SubscriberGroupsEdit/component";
import SysStatsCpu from "views/Statistics/System/SysStatsCpu/component";
import StatsLatency from "views/Statistics/System/StatsLatency/component";
import SysStatsMem from "views/Statistics/System/SysStatsMem/component";
import StatsRtx from "views/Statistics/System/StatsRtx/component";
import { ProfileContextProvider } from "common/ProfileContext";

function toKeyValuePairs(input) {
  const result = {};
  for (let [key, value] of input.entries()) {
    result[key] = value;
  }

  return result;
}

const router = createBrowserRouter([
  {
    path: "/viewIfaceStkStats",
    element: <RouteAdapter Component={Interfaces} />,
  },
  {
    path: "/viewBtaStatsTraffic",
    element: <RouteAdapter Component={BtaTraffic} />,
  },
  {
    path: "/viewStatusSubscribers",
    element: <RouteAdapter Component={SubscribersAndDetails} />,
  },
  {
    path: "/viewSubsDetail",
    element: <RouteAdapter Component={VerifiedSubcriberDetail} />,
  },
  {
    path: "/viewStatusSubscriberGroups",
    element: <RouteAdapter Component={SubscriberGroupsAndDashboard} />,
  },
  {
    path: "/viewSubscriberGroupDashboard",
    element: <RouteAdapter Component={SubscriberGroupDashboard} />,
  },
  {
    path: "/viewStatusSubscriberGroupsEdit",
    element: <RouteAdapter Component={SubscriberGroupEdit} />,
  },
  {
    path: "/viewSysStatsCpu",
    element: <RouteAdapter Component={SysStatsCpu} />,
  },
  {
    path: "/viewSysStatsMem",
    element: <RouteAdapter Component={SysStatsMem} />,
  },
  {
    path: "/viewLatencyStats",
    element: <RouteAdapter Component={StatsLatency} />,
  },
  {
    path: "/viewRtxStats",
    element: <RouteAdapter Component={StatsRtx} />,
  },
  {
    path: "/:view?",
    element: <RouteAdapter />,
  },
]);

export default function App() {
  return (
    <ProfileContextProvider>
      <RouterProvider router={router} />
    </ProfileContextProvider>
  )
}

function RouteAdapter({ Component }) {
  const [ready, setReady] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  function filterParams(input) {
    const output = {};
    for (const [key, value] of Object.entries(input)) {
      if (value !== undefined && value !== null && key !== "dpiEnabled") {
        output[key] = value;
      }
    }

    return output;
  }

  function navigateWithQueryParams(path, queryParams) {
    if(location.pathname === '/viewDashboard'){
      window.unloadDashboardView();
    }
    
    navigate({
      pathname: "/" + path,
      search: createSearchParams(
        queryParams ? filterParams(queryParams) : undefined
      ).toString(),
    });
  }

  function navigateBack() {
    navigate(-1);
  }

  function updateUI() {
    $("#navigationBar").show();
    $("#sectSidebars").show();
    $("#sectContent").hide();
    $("#viewLogin").hide();
    window.views.setMenuActive("#menu" + location.pathname.substring(5));
  }

  function checkJsReady(){
    if(window.subsStatsMetrics && window.login){
      setReady(true);
    }else{
      setTimeout(() => {
        checkJsReady();
      }, 1000)
    }
  }

  useEffect(() => {
    window.globalNavigate = navigateWithQueryParams;
    window.globalNavigateBack = navigateBack;

    checkJsReady();
  }, []);

  useEffect(() => {
    if(!ready){
      return;
    }

    const isLogged = window.login.isLogged();
    if (location.pathname !== "/viewLogin" && !isLogged) {
      return navigate({
        pathname: "/viewLogin",
        search: createSearchParams({
          url: location.pathname.slice(1),
        }).toString(),
      });
    } else if (location.pathname === "/" && location.hash === '' && isLogged) {
      return navigate("/viewDashboard");
    }

    if (!Component && params.view && (params.view === "viewLogin" || isLogged)) {
      const dpiFunctionality = window.functionality.isEnabled("dpi-per-user");
      window.views.toggle(params.view, {
        ...toKeyValuePairs(searchParams),
        dpiEnabled: dpiFunctionality,
      });
    }else if(location.hash !== ''){
      location.href = location.href.replace('#', '');
    }
  }, [params.view, ready]);


  if (!ready || !Component) {
    return null;
  }

  updateUI();

  const dpiEnabled = window.functionality.isEnabled("dpi-per-user");

  return (
    <ActionableView
      Component={Component}
      {...toKeyValuePairs(searchParams)}
      dpiEnabled={dpiEnabled}
    />
  );
}
