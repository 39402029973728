import React, { useState, useEffect, useRef, useCallback, useContext} from "react";
import Modal from "common/Modal.jsx";
import DataTable, { Column } from "common/DataTable";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import {safeStr} from "common/api";
import useProfile from "common/hooks/useProfile";

const doesNothing = () => {};

const DataTableWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
`;

const RatePolicyWrapper = styled.div`
  display: flex;
  align-items: center;

  .rate-policy-title {
    font-size: 14px;
    font-family: "AllianceNo2-SemiBold";
    color: var(--subtitle-color);
    margin-right: 10px;
    font-weight: normal;
  }

  button.red.btn.btn-default.waves-effect {
    color: red;
  }
`;

const RatePolicyLink = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px !important;
`;

// --card-text-color

const ContentWrapper = styled.div`
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  color: var(--card-text-color);

  table.dataTable th {
    font-size: 14px;
    font-weight: 700;
  }
  table.dataTable td i.material-icons {
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: -15px;
`;

const updatedResponse = (response) => {
  const [header, ...rows] = response ? response.trim("\n").split("\n") : [];
  const headerAr = header?.split(/\s+/);
  const limitDnIndex = headerAr?.findIndex((el) => el === "LIMIT-DN");
  const limitUpIndex = headerAr?.findIndex((el) => el === "LIMIT-UP");
  const updatedRows = rows.map((line) => {
    const lineArr = line?.split(/\s+/);
    const newLineArr = lineArr.map((item, index) => {
      if (index !== limitDnIndex && index !== limitUpIndex) {
        return item;
      }
      if (item === "n/a") return item;
      return item / 1000;
    });
    return newLineArr.join("   ");
  });
  return [header, ...updatedRows].join("\n")
}

const ModalContent = ({
  selectedPolicy,
  setSelectedPolicy,
  policyName,
  setError,
  nameGroup,
  returnView = "viewStatusSubscriberGroupsEdit",
  setDisplayModal 
}) => {
  const [policiesResponse, setPoliciesResponse] = useState("");
  const profile = useProfile();
  useEffect(() => {
    async function loadPolicies() {
      try {
        const response = await ifCl.run(`show policy rate full`);
        const [header, ...rows] = response.trim("\n").split("\n");
        const naLine =
          "No-rate-policy             n/a n/a   n/a   n/a         n/a     n/a";
        if (policyName === "n/a") {
          const newResponse = [header, naLine, ...rows].join("\n");
          setPoliciesResponse(newResponse);
        } else {
          const newRows = rows.reduce((acc, value) => {
            const name = value.split(/\s+/)[0];
            if (name === policyName) {
              acc.unshift(value);
            } else {
              acc.push(value);
            }
            return acc;
          }, []);
          const [nameRow, ...otherNewRows] = newRows;
          const newResponse = [header, nameRow, naLine, ...otherNewRows].join(
            "\n"
          );
          setPoliciesResponse(newResponse);
        }
      } catch (error) {
        setError(error);
      }
    }

    loadPolicies();
  }, [policyName]);

  useEffect(() => {
    if (policiesResponse && selectedPolicy) {
      const [header, ...rows] = policiesResponse.trim("\n").split("\n");
      const newRows = rows.reduce((acc, value) => {
        const name = value.split(/\s+/)[0];
        if (name === selectedPolicy) {
          acc.unshift(value);
        } else {
          acc.push(value);
        }
        return acc;
      }, []);
      const newResponse = [header, ...newRows].join("\n");
      setPoliciesResponse(newResponse);
    }
  }, [selectedPolicy]);

  if (!policiesResponse) {
    return <div>No Rate Policies found.</div>;
  }
  return profile === null || profile.hideRates === true ? null : (
    <ContentWrapper>
      <ButtonWrapper>
        <button
          type="button"
          className="btn btn-default waves-effect shadow-none margin-b-10 hidden-to-operators"
          onClick={() => {
            setDisplayModal(false);
            rulesRate.data.policies.addPolicy(
              returnView,
              "",
              { subscriberGroup: nameGroup }
            )
          }}
        >
          <i className="material-icons">add_circle</i>
          <span>Create new rate policy...</span>
        </button>
      </ButtonWrapper>
      <DataTableWrapper>
        <DataTable
          pageLength={10}
          content={updatedResponse(policiesResponse)}
          columns={[
            Column.Text({
              label: "NAME",
              idx: 0,
            }),
            Column.NumberOrNA({
              label: "DN-Mbps",
              idx: 5,
              colClassName: "text-right",
              precision: 3,
            }),
            Column.NumberOrNA({
              label: "UP-Mbps",
              idx: 6,
              colClassName: "text-right",
              precision: 3,
            }),
            Column.Actions({
              label: "SELECTED",
              idx: 0,
              colClassName: "text-center action",
              are: (name, row) => {
                if (name === selectedPolicy) {
                  return [
                    {
                      id: "selected",
                      label: "Selected",
                      icon: "check_box",
                      onClick: (policyName) => {
                        setSelectedPolicy(policyName);
                      },
                    },
                  ];
                }
                return [
                  {
                    id: "select",
                    label: "Select",
                    icon: "check_box_outline_blank",
                    onClick: (policyName) => {
                      setSelectedPolicy(policyName);
                    },
                  },
                ];
              },
            }),
          ]}
        />
      </DataTableWrapper>
    </ContentWrapper>
  );
};

const PoliciesAssignModal = ({
  onClose = doesNothing,
  setDisplayModal,
  policyName,
  nameGroup,
  setPolicyName,
  returnView,
}) => {
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (policyName === "n/a") {
      setSelectedPolicy("No-rate-policy");
    } else {
      setSelectedPolicy(policyName);
    }
  }, []);

  const handleSelectPolicy = async () => {
    try {
      let response;
      if (selectedPolicy === "No-rate-policy") {
        response = await ifCl.run(
          `clear api subscriber-group ${safeStr(nameGroup)} shaping policy`
        );
      } else {
        response = await ifCl.run(
          `api subscriber-group ${safeStr(nameGroup)} shaping policy ${safeStr(
            selectedPolicy
          )}`
        );
      }
      const newPolicyName =
        selectedPolicy === "No-rate-policy" ? "n/a" : selectedPolicy;
      setPolicyName(newPolicyName);
      setDisplayModal(false);
    } catch (error) {
      setErrror(error);
    }
  };

  return (
    <Modal
      title="Select Rate Policy"
      superIcon="edit"
      content={() => (
        <ModalContent
          setSelectedPolicy={setSelectedPolicy}
          selectedPolicy={selectedPolicy}
          policyName={policyName}
          setError={setError}
          nameGroup={nameGroup}
          returnView={returnView}
          setDisplayModal={setDisplayModal}
        />
      )}
      applyLabel="OK"
      onApply={handleSelectPolicy}
      applyDisabled={
        selectedPolicy === policyName ||
        (selectedPolicy === "No-rate-policy" && policyName === "n/a")
      }
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <div className="row">
            <span className="modal-err-msg color-red align-left">{error}</span>
          </div>
        )) ||
        null
      }
    />
  );
};

const PolicyNameC = ({ policyName, nameGroup, returnView, settings }) => {
  const [assignment, setAssignMent] = useState();
  const profile = useProfile();
  useEffect(() => {
    async function loadPolicyType() {
      try {
        const response = await ifCl.run(`show api policy ${safeStr(policyName)}`);
        const assignmentResponse = response
          ?.trim("\n")
          .split("\n")
          .find((element) => element.includes("Assignment"));
        const assignment = assignmentResponse?.split(": ")[1];
        setAssignMent(assignment);
      } catch (error) {
        console.log(error);
      }
    }
    if (policyName && policyName !== "n/a") {
      loadPolicyType();
    }
  }, [policyName]);

  if (policyName === "n/a") {
    return <span>{policyName}</span>;
  }

  const className = `btn btn-default waves-effect shadow-none btn-policy-rate ${
    assignment === "undefined" ? "red" :
    profile && profile.hideRates === true ? "disabled" :
    "hyperlink-text"
  }`;

  function confirmCreatePolicy() {
    return new Promise((resolve, reject) =>
      showModalConfirm(
        `Confirm before continue`,
        `Warning: This policy is undefined, and has been assigned to some subscribers or subscriber-groups. Those subscribers are now getting a default policy from the configured rules, while thos subscriber-groups are getting no rate limitation. If you click "Define", you can now define this policy and then the assigned subscribers and groups will get it.`,
        "warning",
        () => resolve(),
        () => reject(),
        "DEFINE",
        "RETURN"
      )
    );
  }

  function offerDefineIfNotFound(name) {
    confirmCreatePolicy()
      .then(() => {
        rulesRate.data.policies.addPolicy(
          returnView,
          name,
          { subscriberGroup: nameGroup, ...settings }
        );
      })
      .catch(() => null);
  }

  function openRatePolicy(name, assignment) {
    ifCl
      .run(`show policy ${safeStr(name)}`)
      .then((response) => {
        const policy = rulesRate.data.policies.parsePolicy(response);

        return assignment === "assigned"
          ? rulesRate.data.policies.showDynPolicy(policy, response, true)
          : assignment === "configured"
          ? rulesRate.data.policies.editPolicy(policy, returnView, {
              subscriberGroup: nameGroup,
              ...(settings !== undefined && settings),
            })
          : assignment === "undefined"
          ? offerDefineIfNotFound(name)
          : null;
      })
      .catch(() => {
        offerDefineIfNotFound(name)
      });
  }

  return (
    <RatePolicyLink
      type="button"
      className={className}
      onClick={profile && profile.hideRates === true ? null :
        () => openRatePolicy(policyName, assignment)}
    >
      {assignment === "undefined" ? (
        <i className="material-icons">warning</i>
      ) : null}
      {policyName}
    </RatePolicyLink>
  );
};

const parseSubscriberGroupRatePolicy = response => {
  const lines = response.split("\n");
  const policyNameLine = lines.find((element) =>
    element.includes("Rate-Policy")
  );
  const [_, policyName] = policyNameLine ? policyNameLine.split(": "): [];
  return policyName;
}

const useSubscriberGroupRatePolicy = (groupName) => {
  const [policyName, setPolicyName] = useState(null);
  const [request, setRequest] = useState(null);
  
  const doLoad = () =>
    ifCl.run(`show api subscriber-group name ${safeStr(groupName)} info`)
      .then(parseSubscriberGroupRatePolicy)
      .then(setPolicyName)
      .catch( error => {
        if (error.includes("ERR-ENOENT")) {
          setPolicyName("n/a");
        } else {
          console.log(error);
        }
      })

  useEffect( () => {
    setPolicyName(null);
    doLoad();
  }, [groupName]);

  return [policyName, setPolicyName, doLoad];
} 

const RatePolicy = ({ nameGroup, returnView, settings, className}) => {
  const [policyName, setPolicyName, doLoad] = useSubscriberGroupRatePolicy(nameGroup);
  const [displayModal, setDisplayModal] = useState(false);
  const actions = useContext(ActionsContext);
  const profile = useProfile();

  useEffect(() =>
    actions.recv('do-load', doLoad)
  , []);

  return (
    <>
      {profile === null || policyName === null ? null : (
        <RatePolicyWrapper className={className}>
          <div className="rate-policy-title">Rate Policy:</div>
          <PolicyNameC policyName={policyName} nameGroup={nameGroup} returnView={returnView} settings={settings}/>
          { profile.isOperator === true ? null :
            <button
              type="button"
              className="btn btn-default waves-effect shadow-none"
              onClick={() => setDisplayModal(true)}
            >
              <i className="material-icons">edit</i>
            </button>
          }
        </RatePolicyWrapper>
      )}

      {displayModal &&
        ReactDOM.createPortal(
          <PoliciesAssignModal
            onClose={() => setDisplayModal(false)}
            setDisplayModal={setDisplayModal}
            policyName={policyName}
            nameGroup={nameGroup}
            setPolicyName={setPolicyName}
            returnView={returnView}
          />,
          document.body
        )}
    </>
  );
};

export default RatePolicy;
