import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
} from "./constants";

/**************************************************
 * Validate IPs
 **************************************************/
export const validateIPv4IPv6 = (address) => {
  const ipv46_regex =
    /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;

  return ipv46_regex.test(address);
};

export const validateIPv4 = (address) => {
  const ipv4_regex =
    /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/gm;

  return ipv4_regex.test(address);
};

export const validateIPv6 = (address) => {
  const ipv6_regex =
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$/gm;

  return ipv6_regex.test(address);
};

export const validateIPv4WithMask = (address) => {
  const ipv4_regex =
    /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/gm;

  return ipv4_regex.test(address);
};

export const validateIPv6WithMask = (address) => {
  const ipv6_regex =
    /^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?(\/([1-9]|[0-9][0-9]|1[0-2][0-8]))?$/gm;

  return ipv6_regex.test(address);
};

export const isIPv4IPv6Range = (input) =>
  input.search(/\/.*$/) !== -1 && validateIPv4IPv6Range(input);

export const validateIPv4IPv6Range = (input) =>
  validateIPv4IPv6(input.replace(/\/.*$/, ""));

export const isValidIPValue = (value) =>
  value.length === 0 || validateIPv4IPv6Range(value) === true;

export const parseNames = (input) => {
  const [_head, ...lines] = input.trim("\n").split("\n");
  const names = lines.map((line) => line.replace(/\s.*$/, ""));
  return { names };
};

export const parseColumnValues = (input, indexes, limit) => {
  const result = [];
  const [_head, ...lines] = input.trim("\n").split("\n");
  let finishedCount = 0;
  let finished = {};
  for (let line of lines) {
    const words = line.split(/\s+/);
    for (let index of indexes) {
      if (!result[index]) {
        result[index] = new Set([words[index]]);
      } else if (!limit || result[index].size < limit) {
        result[index].add(words[index]);
      }

      if (result[index].size === limit && !finished[index]) {
        finished[index] = true;
        finishedCount++;
      }
    }

    if (finishedCount === indexes.length) {
      break;
    }
  }

  return result;
};

export const getIPv6Prefix = () =>
  ifCl.run("show subscriber ipv6-prefix").then((resp) => resp.trim("\n"));
// This promise will be kept resolved for next calls.

const expressIPv6Addr = (addr, ipv6Mask = true) =>
  ipv6Mask !== true
    ? Promise.resolve(addr)
    : getIPv6Prefix().then(
        (ipv6prefix) => `${addr}/${ipv6prefix}`
      ); /*IPv6 missing prefix*/

const addsKeyword = (addressKeyword = null) =>
  addressKeyword === null
    ? (result) => result
    : (result) => `${addressKeyword} ${result}`;

export const expressedAddr = (
  addr,
  { ipv6Mask = true, addressKeyword = null }
) =>
  addr.includes(":") && addr.match(/\/\d+$/) === null
    ? expressIPv6Addr(addr, ipv6Mask).then(addsKeyword(addressKeyword))
    : addsKeyword(addressKeyword)(addr);

const quoteWrap = input => `'${input}'`;

const allSpecialSymbols = /[#"'|\\]/g;
const escapedSymbol = '\\$&';

const safeStrUnderscore = input =>
  input
    .trim(/\s/)
    .replace(/\s/g, "_")
    .replace(allSpecialSymbols, escapedSymbol);

const safeStrKeepSpaces = input =>
  input
    .replace(allSpecialSymbols, escapedSymbol);

const _SAFE_STR_DEFAULTS = {
  preserveSpaces: false,
};

export const safeStr = (input, options = _SAFE_STR_DEFAULTS) =>
  quoteWrap(
    options.preserveSpaces === true
      ? safeStrKeepSpaces(String(input))
      : safeStrUnderscore(String(input))
  );

export const safeSubsId = input => safeStr(input);

const _default_options = {
  subsIdKeyword: "id",
  ipv6Mask: true,
  addressKeyword: null,
  noTarget: "all",
};

export const expressTarget = (
  { addr = null, subsId = null },
  options = _default_options
) => {
  const { subsIdKeyword = "id" } = options;
  return Promise.resolve(
    addr !== null && addr.startsWith("id ") === false
      ? expressedAddr(addr, options)
      : subsId !== null
      ? `${subsIdKeyword} ${safeSubsId(subsId)}`
      : options.noTarget || "all"
  );
};

export const parsePoliciesIntoSets = (response) => {
  const lines = response.trim("\n").split("\n");
  const [_head, ...rows] = lines;
  return rows.reduce(mergeIntoSets, initialPolicySets());
};

const mergeIntoSets = ({ types, sources }, row) => {
  const [name, type, source] = row.split(/\s+/);
  types = {
    ...types,
    [type]: type in types ? types[type].add(name) : new Set([name]),
  };
  sources = {
    ...sources,
    [source]: source in sources ? sources[source].add(name) : new Set([name]),
  };
  return { sources, types };
};

const initialPolicySets = () => ({
  types: { rate: new Set(), flow: new Set() },
  sources: { static: new Set() },
});

export const parseSystemDate = (input) =>
  new Date(input.replace(/(\+|-)[0-9]+/, ""));

export const doSystemDateRetrieval = () => ifCl.run("show system date");

export const calcRangeExtent = (end, hours) => {
  const start = new Date(end.getTime() - hours * 3600 * 1000);
  return { from: start, to: end };
};

export const retrieveRange = (hours) =>
  doSystemDateRetrieval()
    .then(parseSystemDate)
    .then((now) => calcRangeExtent(now, hours));

export function getSystemDate() {
  return ifCl.run("show system date");
}

const parseStatementItem = (row) => {
  const [type, status, _updates, timeStr] = row.split(/\s+/);
  return {
    type,
    status,
    time: new Date(timeStr),
  };
};
const involvedStatementsRE = /^license-(available|expiration)/;
const parseLicenseStatements = (input) => {
  const lines = input.trim("\n").split("\n");
  const [_head, ...rows] = lines;
  return rows
    .filter((row) => row.match(involvedStatementsRE))
    .map(parseStatementItem);
};
const checkLicenseStatus = (statements) =>
  statements.filter(({ status, type }) => {
    if (status !== "normal") {
      throw `License statement for ${type} is ${status} not 'normal'`;
    }
    return true;
  });

const handleStatementRejectionError = (warn) => (error) => {
  if (String(error).startsWith("License statement")) {
    if (warn === true) {
      console.warn("rejected due to:", error);
    }
    return false; // not valid;
  }
  console.error(error);
  throw error;
};

export const checkLicense = (input, warn = true) =>
  Promise.resolve(parseLicenseStatements(input))
    .then(checkLicenseStatus)
    .then((statements) => statements.length === 2)
    .catch(handleStatementRejectionError(warn));

const securityFieldTranslations = {
  "Access lock failures": "maxFailures",
  "Access unlock time": "unlockTime",
  "Password minlen": "passwordMinLength",
  "Password lcredit": "passwordLCredit",
  "Password ucredit": "passwordUCredit",
  "Password dcredit": "passwordDCredit",
  "Password ocredit": "passwordOCredit",
  "Password enforce for root": "enforceRoot",
  "Password reject username": "enforceUsername",
};

const asSecurityFieldValue = (row) => {
  const [fieldStr, valueStr] = row.split(/:\s+/);
  const field = securityFieldTranslations[fieldStr] || fieldStr;
  const value =
    valueStr === "n/a"
      ? null
      : valueStr === "yes"
      ? true
      : valueStr === "no"
      ? false
      : parseInt(valueStr);
  return [field, value];
};

const intoSecuritySettings = (settings, [field, value]) => ({
  ...settings,
  [field]: value,
});

const _SECURITY_DEFAULTS = {
  maxFailures: 0,
  unlockTime: 0,
  passwordMinLength: 0,
  passwordLCredit: 0,
  passwordUCredit: 0,
  passwordDCredit: 0,
  passwordOCredit: 0,
  enforceRoot: false,
  enforceUsername: false,
};

export const parseSystemSecurity = (response) =>
  response
    .trim("\n")
    .split("\n")
    .map(asSecurityFieldValue)
    .reduce(intoSecuritySettings, _SECURITY_DEFAULTS);

const notNAAndHigherOrEqualTo = (threshold) => (value) =>
  value !== null && value >= threshold;
const notNAAndLowerOrEqualTo = (threshold) => (value) =>
  value !== null && value <= threshold;
const validCredit = notNAAndLowerOrEqualTo(-1);

export const checkSystemSecurityActive = ({
  maxFailures,
  unlockTime,
  passwordMinLength,
  passwordLCredit,
  passwordUCredit,
  passwordDCredit,
  passwordOCredit,
  enforceUsername,
  enforceRoot,
}) =>
  notNAAndHigherOrEqualTo(4)(maxFailures) &&
  notNAAndHigherOrEqualTo(300)(unlockTime) &&
  notNAAndHigherOrEqualTo(9)(passwordMinLength) &&
  validCredit(passwordLCredit) &&
  validCredit(passwordDCredit) &&
  validCredit(passwordOCredit) &&
  validCredit(passwordUCredit) &&
  enforceRoot === true &&
  enforceUsername === true;

const notAllowedUsernames = new Set(['admin', 'administrator','administration', 'root', 'system']);

export const validUsername = (username = '') => {
  if (notAllowedUsernames.has(username.toLowerCase())) {
    throw `${username} is not an allowed username`
  }
  if (username.length < 5) {
    throw 'Minimun user name length is 5 characters';
  }
  if (username.length > 5) {
    throw 'Maximum user name length is 16 characters';
  }
  return username
}

export function getSampleSize(range, POINTS_PER_TILE_CHART, period){
  if(!range){
    return 1;
  }
  const {from, to} =range;
  const begin = new Date(from)
  const end = new Date(to)
  const timeDiffinHours = (end.getTime()- begin.getTime()) / (1000*60*60);
  const lines = (timeDiffinHours / PERIOD_TO_HOURS[period])*PERIOD_TO_LINES[period]
  const sampleSize = Math.floor(lines/POINTS_PER_TILE_CHART) + 1;
  return sampleSize;
};

export const isInsideRange =
  ({ from, to }, field = "time") =>
  (item) =>
    from <= item[field] && item[field] <= to;


export const getMeanValue = (values) => {
  if (!values) {
    return "n/a";
  }
  const sum = values.reduce((acc, value)=> {
    acc = acc + value;
    return acc;
    }, 0)
    
  return sum/values.length;
}