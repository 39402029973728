import React, { useState, useContext } from "react";
import ActionsContext from "common/ActionsContext";
import Modal from "common/Modal.jsx";

const StatsRtxHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="Average TCP Retransmissions Over Time"
      onClose={doClose}
      content={() => (
        <>
           <ul>
          <li><b>Rtx-Access</b>: Average retransmissions percentage between the BQN and the subscribers on the acess side.</li>
          <li><b>Rtx-Internet</b>:  Average retransmissions percentage between the BQN and the servers on the Internet side.</li>
        </ul>
        <p>Chart selectors:</p>
        <ul>
          <li><i class="material-icons valign-icon">date_range</i> Selects the range of time values shown starting from now: 1 day, 1 week or 1 month. The slider at the bottom of the graph allows refining the range of date values shown: a shorter interval by dragging any of the dated labels and, once done, by dragging the central part</li>
        </ul>
        <p>Click on the coloured dots on top-right corner of the graph to optionally select and de-select which RTT is shown.</p>
        <p>Double-click on the coloured dots on top-right corner of the graph to optionally isolate the selected RTT.</p>
        <p>Click on the <i class="material-icons valign-icon">refresh</i> icon to refresh the data.</p>
        </>
      )}
      onClose={doClose}
    />
  );
};
export default StatsRtxHelpModal;
